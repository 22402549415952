<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
        class="qrcode-wrap"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <v-row class="pb-1">
        <v-col
          class="pb-0 pt-0"
          :class="{'pr-0': $store.getters.getMobileModel !== 'PDA' }"
          :cols="$store.getters.getMobileModel !== 'PDA' ? 11 : 12"
        >
          <v-autocomplete
            id="input-pcode"
            ref="refPcode"
            v-model="selectedPCode"
            :items="purchaseInfoList"
            label="품번"
            :item-text="getText"
            item-value="품번"
            clearable
            auto-select-first
            @change="onPCodeChange"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-4 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0 pt-0"
          cols="6"
        >
          <v-text-field
            id="input-qty"
            v-model="inputQty"
            label="출고중량(Kg)"
            autocomplete="off"
            type="number"
            clearable
            @keyup.enter.prevent="onEnterQty"
          />
        </v-col>
        <v-col
          class="pb-0 pt-0"
          cols="6"
        >
          <v-text-field
            id="input-lotNo"
            v-model="inputLotNo"
            label="롯트번호"
            type="number"
            autocomplete="off"
            @keyup.enter.prevent="onEnterLotNo"
          />
        </v-col>
      </v-row>
      <v-row
        class="mt-0 mb-0 pt-1"
      >
        <v-col
          cols="12"
        >
          <v-btn
            width="100%"
            color="primary"
            @click="onEnterLotNo"
          >
            {{ 등록구분명 }}
          </v-btn>
        </v-col>
      </v-row>
      <v-text-field
        v-model="selectedGoodsName"
        label="품명"
        autocomplete="off"
        :readonly="true"
        filled
      />
      <v-row>
        <v-col
          cols="4"
        >
          <v-text-field
            :value="unitBom"
            label="BOM단위"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
        <v-col
          cols="4"
        >
          <v-text-field
            :value="unitText"
            label="주문단위"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
        <v-col
          cols="4"
        >
          <v-text-field
            v-model="unitRate"
            label="변환율"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
      </v-row>
      <div style="margin-top:10px; background-color:white;">
        <dx-data-grid
          ref="refGoodsInputGrid"
          :data-source="goodsOutputListInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeightSub"
          :focused-row-enabled="true"
          key-expr="발주번호"
          :focused-row-key.sync="focusedRowKey"
          @row-removed="onRowRemoved"
        >
          <DxEditing
            :allow-deleting="true"
            :allow-updating="false"
            :allow-adding="false"
            :use-icons="true"
            mode="row"
          />
          <DxColumn
            :allow-editing="false"
            caption="일련번호"
            data-field="seqId"
            :visible="false"
          />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
            width="140"
          />
          <DxColumn
            :allow-editing="false"
            caption="출고수량"
            data-field="수량"
            format="#,##0"
          />
          <DxColumn
            :allow-editing="false"
            caption="코일수"
            data-field="코일수"
            format="#,##0"
            width="60"
            :visible="false"
          />
          <DxColumn
            width="50"
            :buttons="editButtons"
            type="buttons"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              show-in-column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
    </v-container>
  </div>
</template>
<script>
import DxDataGrid, { DxScrolling, DxColumn, DxEditing, DxPaging, DxSorting, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'
import axios from 'axios'

export default {
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    // DxSearchPanel,
    DxSummary,
    DxTotalItem,
    DxEditing,
    QrCodeScanComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      isSaving: false,
      deliveryDetailListInfo: null,
      gridHeight: null,
      today: AppLib.getToday(),
      datePickerWidth: '350px',
      focusedRowKeyOrder: null,
      selectedOrder: null,
      htmlWdth: null,
      modal: false,
      입고업체: null,
      사급소재협력사코드: null,
      // slide view
      showQrScaner: 'none',
      purchaseInfoList: [],
      selectedPCode: null,
      inputQty: null,
      inputCoilQty: null,
      inputLotNo: null,
      selectedGoodsName: null,
      unitText: null,
      unitBom: null,
      unitRate: null,
      goodsOutputListInfo: [],
      gridHeightSub: null,
      focusedRowKey: null,
      editButtons: ['delete'],
      등록구분명: '출고 등록',
      사급여부: false,
      goodsType: ConstDef.자재유형_원자재
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.unitLookup = this.$store.getters.getBaseDataByType(ConstDef.단위)
    // this.refreshDeliveryList()
    this.refreshPurchaseInfo()
    this.refreshInputGoodsList()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.gridHeight = (this.$Q('html').height() - 145)
    this.handleResize()
  },
  updated () {
  },
  methods: {
    getText (row) {
      let 자사두께 = ''
      let 자사가로 = ''
      let returnText = null
      if (row.품번 !== null && row.품번 !== undefined) {
        if (row.자사두께 !== null && row.자사두께 !== undefined) { 자사두께 = row.자사두께 * 10 }
        if (row.자사가로 !== null && row.자사가로 !== undefined) { 자사가로 = row.자사가로 }
        returnText = `${row.품번}(${자사두께}${자사가로})`
      }
      return returnText
    },
    handleResize () {
      this.htmlWdth = this.$Q('html').width()
      if (this.$Q('.left-draw-wrap').css('left') !== '0px') {
        this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
      }
      this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)

      this.gridHeightSub = (this.$Q('html').height() - 425)
    },
    onClickInputEnd () {
      this.selectedOrder = null
      this.showSlide(false)
    },
    onSelectionChangedDetail (e) {
      if (e != null && e.row === undefined) {
        return
      }
      this.selectedOrder = e.row.data
      this.selectedPCode = e.row.data.품번
      this.inputQty = e.row.data.납품수량
      this.입고업체 = e.row.data.거래처코드
      this.showSlide(true)
    },
    async addInventory () {
      if (this.isSaving) return
      this.deleveryDateTime = AppLib.getNow()
      let seqId = null
      let 입고여부 = true
      if (this.selectedOrder !== null) {
        await this.$_sp.runNoEncodeFindSqlProc('창고수불', { 발주번호: this.selectedOrder.seqId })
          .then((data) => {
            if (this.$_sp.MakeModel(data).length > 0) {
              입고여부 = false
            }
          })
        seqId = this.selectedOrder.seqId
      } else {
        seqId = AppLib.uuidSequential()
      }
      if (입고여부 === false) {
        this.$snotify.error('이미 입고처리 되었습니다.')
        return
      }
      let 단가 = 0
      await this.$_sp.runNoEncodeFindProc('spFindAllCostOrSaleByGoodsCodeAndSaleType', { 입출고구분: 1, 품번: this.selectedPCode, 기준일: AppLib.getNow() })
        .then((data) => {
          const 가격정보 = this.$_sp.MakeModel(data)
          if (가격정보.length > 0) {
            단가 = 가격정보[0].가격
          } else {
            this.$snotify.info('가격정보가 등록되어있지 않습니다. 추후 수정하여주세요.')
          }
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      // 더블클릭방지(이중클릭)
      this.isSaving = true
      const param = [
        {
          발생일자: this.deleveryDateTime,
          입출고구분: ConstDef.용변출고,
          품번: this.selectedPCode,
          수량: Math.round(parseInt(this.inputQty) * this.unitRate),
          코일수: 0,
          단가: 단가,
          입고업체: this.입고업체,
          구매구분: ConstDef.사급,
          발주번호: seqId,
          lotNo: this.inputLotNo,
          createid: this.userInfo.userName,
          updateid: this.userInfo.userName,
          createtime: AppLib.getNow()
        }
      ]
      this.$_sp.runInsertSqlProc('창고수불', null, param)
        .then((data) => {
          this.isSaving = false
          this.goodsOutputListInfo.push(param[0])
          this.$snotify.info('출고처리 되었습니다.')
          if (this.selectedOrder === null) {
            this.initInput(false)
          } else {
            this.selectedOrder.입고수량 = this.inputQty
            setTimeout(() => {
              this.onClickBefore()
            }, 400)
          }
        })
        .catch(error => {
          this.isSaving = false
          this.initInput(false)
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    initInput (isSlide) {
      this.inputQty = null
      this.inputLotNo = null
      this.setValue(null, null, null, null, null)
      this.selectedPCode = null
      if (isSlide === false) {
        setTimeout(() => {
          this.$Q('#input-pcode').focus()
        }, 10)
      }
    },
    onEnterPcode () {
      this.selectedPCode = this.selectedPCode.replace('?', ' ')
      const val = AppLib.base64DecodeSubstring(this.selectedPCode)
      const row = this.$_.findWhere(this.purchaseInfoList, { 품번: val })
      this.setValue(row.품번, row.단위, row.구매단위, row.변환배율, row.품명)
      this.입고업체 = row.거래처코드
      this.$Q('#input-pcode').text(val)
    },
    onEnterQty (e) {
      if (this.inputQty === undefined || this.inputQty === null || !AppLib.isNumeric(this.inputQty)) {
        return this.$snotify.error('수량을 입력하여 주세요')
      }
      if (this.selectedPCode === null || this.selectedPCode.length === 0) {
        return this.$snotify.error('품목 정보를 선택하여 주세요')
      }
      this.$Q('#input-lotNo').focus()
    },
    onEnterLotNo (e) {
      if (this.selectedPCode === null || this.selectedPCode.length === 0) {
        return this.$snotify.error('품목 정보를 선택하여 주세요')
      }
      if (this.inputQty === undefined || this.inputQty === null || !AppLib.isNumeric(this.inputQty)) return this.$snotify.error('수량을 입력하여 주세요')
      this.$Q('#input-pcode').focus()
      this.addInventory()
    },
    setValue (goodsNo, unitBom, unitText, unitRate, goodsName) {
      this.selectedPCode = goodsNo
      this.selectedGoodsName = goodsName

      this.unitText = unitText
      if (unitText !== undefined && unitText !== null) {
        this.unitText = this.$_.findWhere(this.unitLookup, { 코드: unitText }).코드명
      }

      this.unitBom = unitBom
      if (unitBom !== undefined && unitText !== null) {
        this.unitBom = this.$_.findWhere(this.unitLookup, { 코드: unitBom }).코드명
      }
      this.unitRate = unitRate
    },
    onPCodeChange (goodsNo) {
      const row = this.$_.findWhere(this.purchaseInfoList, { 품번: goodsNo })
      this.setValue(row.품번, row.단위, row.구매단위, row.변환배율, row.품명)
      this.입고업체 = row.거래처코드
      setTimeout(() => {
        this.showQrScaner = 'none'
        this.$Q('#input-qty').focus()
        this.$Q('.v-autocomplete__content').css('display', 'none')
      }, 300)
    },
    onRowRemoved (row) {
      this.$_sp.runDeleteSqlProc('창고수불', [{ 발주번호: row.data.발주번호 }])
        .then((data) => {
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onPCodeScan (result) {
      this.selectedPCode = result
      this.onEnterPcode(null)
    },
    refreshInputGoodsList () {
      const param = {
        입출고구분: ConstDef.용변출고,
        입고업체: null,
        userId: this.userInfo.userId
      }
      this.$_sp.runNoEncodeFindProc('spFindAllInputGoosListByDelNoAndToday', param)
        .then((data) => {
          this.goodsOutputListInfo = this.$_sp.MakeModel(data)
        })
    },
    refreshPurchaseInfo () {
      const array = []
      array.push(this.$_sp.runNoEncodeFindProc('spFindAllPurchaseByType', { 자재유형: this.goodsType }))
      axios.all(array)
        .then(axios.spread((...reponse) => {
          this.isLoading = false
          this.purchaseInfoList = this.$_sp.MakeModel(reponse[0])
        }))
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
